<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item label="" prop="area_id">
        <sale-area
          ref="saleArea"
          @set-sale-select-id="selectSaleArea"
        ></sale-area>
      </el-form-item>
      <el-form-item label="" prop="channel_id">
        <el-select
          v-model="form.channel_id"
          clearable
          placeholder="渠道"
          style="width: 160px"
        >
          <el-option
            v-for="item in channelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="class_id">
        <el-select
          v-model="form.class_id"
          clearable
          placeholder="客户等级"
          style="width: 160px"
        >
          <el-option
            v-for="item in clientLevelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="staff_id">
        <el-select
          v-model="form.staff_id"
          clearable
          placeholder="业务员"
          style="width: 160px"
        >
          <el-option
            v-for="item in staffList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="time">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 260px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="client_id">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-id="selectClient"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="warning" @click="resetForm">重置</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :summary-method="summaryFunction"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import _ from 'lodash'
  import {
    getClientLevelList,
    getClientChannelList,
    getStaffList,
  } from '@/api/setPrice'
  import ClientSearch from '@/baseComponents/clientSearch'
  import SaleArea from '@/baseComponents/saleAreaSelectTree'
  import { clientAnalysisList } from '@/api/report'
  import { downloadFile } from '@/api/Employee'
  export default {
    name: 'ClientAnalysis',
    components: { ClientSearch, SaleArea },
    data() {
      return {
        loading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        time: [],
        staffList: [],
        clientLevelList: [],
        channelList: [],
        form: {
          pageNo: 1, //
          pageSize: 10, //
          sort: '', //
          order: '', //
          staff_id: '', //业务员
          area_id: '', //区域
          channel_id: '', //渠道
          class_id: '', //等级
          cust_id: '', //客户ID
          start_time: '', //开始时间
          end_time: '', //结束时间
          keyword: '', //客户关键词
        },
        list: [],
        checkList: [
          '客户名称',
          '销售区域',
          '渠道',
          '等级',
          '老板',
          '电话',
          '订单笔数',
          '订单总额',
          '使用预存款',
          '还货金额',
          '净销售金额',
          '优惠金额',
          '退货金额',
          '毛利',
        ],
        columns: [
          {
            order: 1,
            label: '客户名称',
            prop: 'cust_name',
            width: '120',
          },
          {
            order: 2,
            label: '销售区域',
            prop: 'area_name',
            width: '110',
          },
          {
            order: 3,
            label: '渠道',
            prop: 'channel_name',
            width: '80',
          },
          {
            order: 4,
            label: '等级',
            prop: 'class_name',
            width: '70',
          },
          {
            order: 5,
            label: '老板',
            prop: 'boss',
            width: '80',
          },
          {
            order: 6,
            label: '电话',
            prop: 'mobile',
            width: '110',
          },
          {
            order: 7,
            label: '订单笔数',
            prop: 'num',
            width: '80',
          },
          {
            order: 8,
            label: '订单总额',
            prop: 'total_amount',
            width: '80',
          },
          {
            order: 9,
            label: '使用预存款',
            prop: 'deposit_amount',
            width: '90',
          },
          {
            order: 10,
            label: '还货金额',
            prop: 'return_amount',
            width: '80',
          },
          {
            order: 11,
            label: '净销售金额',
            prop: 'sale_amount',
            width: '90',
          },
          {
            order: 12,
            label: '优惠金额',
            prop: 'discount_amount',
            width: '80',
          },
          {
            order: 13,
            label: '退货金额',
            prop: 'refund_goods_amount',
            width: '80',
          },
          {
            order: 14,
            label: '毛利',
            prop: 'gross_profit_amount',
            width: '80',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      // 获取业务员下拉
      getStaffList().then((res) => {
        if (res.code == 200) this.staffList = res.data
      })
      // 获取客户等级下拉
      getClientLevelList().then((res) => {
        if (res.code == 200) this.clientLevelList = res.data
      })
      // 获取渠道下拉
      getClientChannelList().then((res) => {
        if (res.code == 200) this.channelList = res.data
      })
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, totalCount } = await clientAnalysisList(this.form)
        this.list = data.rows
        this.total = totalCount || 0
        this.loading = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      selectSaleArea(val) {
        this.form.area_id = val
      },
      selectClient(val) {
        this.form.cust_id = val
      },
      resetForm() {
        this.$refs.form.resetFields()
        this.$refs.saleArea.resetForm()
        this.$refs.clientSearch.resetForm()
        this.form.cust_id = ''
        this.time = []
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [7, 8, 9, 10, 11, 12, 13, 14]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                if (idx == 7) {
                  sums[n] = a
                } else {
                  sums[n] = a.toFixed(2) + ' 元'
                }
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      handleExport() {
        downloadFile(
          '/reportAdmin/analysis/cust-sale-list-export',
          '客户分析.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
